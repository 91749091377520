export const allLocales = [
  {
    code: 'en',
    abbreviation: 'En',
    label: 'English',
  },
  {
    code: 'ru',
    abbreviation: 'Ру',
    label: 'Русский',
  },
  {
    code: 'es',
    abbreviation: 'Es',
    label: 'Español',
  },
  {
    code: 'tr',
    abbreviation: 'Tr',
    label: 'Türk',
  },
  {
    code: 'pt',
    abbreviation: 'Pt',
    label: 'Português',
  },
  {
    code: 'dev',
    abbreviation: 'Dev',
    label: 'Dev',
  },
];
